import Api from '@/services/Index';

const getInstructorSession = async (id,config={}) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/esl/attendances/${id}/sessions`,config);
}
const getWeeksInformation = async (id,week) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/esl/attendances/${id}/${week}`);
}

const saveAttendance = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(`/esl/attendances`,formData);
}



export default {
    getInstructorSession,
    getWeeksInformation,
    saveAttendance
}
