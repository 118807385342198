<template>
    <app-layout>
        <template v-slot:header>
            <Header :title="$t('esl_attendance')"
                    :isColumns="false"
                    :isFilter="false"
            >
            </Header>
        </template>
        <template v-slot:header-mobile>
            <HeaderMobile :title="$t('esl_attendance')"
                          :isColumns="false"
                          :isFilter="false"
            >
            </HeaderMobile>
        </template>
        <div>
            <b-col v-if="!students.length" class="d-flex justify-content-center align-items-center" style="min-height: 400px">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
            </b-col>
            <div v-else>
                <b-col class="d-flex flex-row align-items-center justify-content-center border p-5">
                    <h6>week</h6>
                    <b-col cols="1">
                        <b-form-select v-model="week" :options="weekOptions"></b-form-select>
                    </b-col>

                </b-col>
                <b-table-simple class="border"  small caption-top responsive bordered>
                    <thead>
                    <tr>
                        <th rowspan="2" class="text-center justify-center">#</th>
                        <th rowspan="2" class="text-center justify-center">ID#</th>
                        <th rowspan="2" class="text-center justify-center">Picture</th>
                        <th rowspan="2" class="text-center justify-center">Student's Legal Name</th>
                        <th v-for="day in weekDaysOptions" :key="'header-' + day.date" :colspan="hoursInDay" class="border-bottom text-center" >
                            <div class="d-flex flex-column align-items-center mb-2">
                                <span >{{ day.name }} ({{ day.date }})</span>
                            </div>
                        </th>
                    </tr>
                    <tr>

                        <template v-for="(day) in weekDaysOptions">
                            <th v-for="(i,key) in hoursInDay" :key="'lesson-' + day.date + '-' + i">
                                <b-form-checkbox
                                    :id="'checkbox-' + day.date + '-' + i"
                                    v-model="day.lessons[key].isDisabled"
                                    @change="handleSelectedHours(day.lessons[key],day)"
                                >
                                    {{day.lessons[key].name}}
                                </b-form-checkbox>
                            </th>

                        </template>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(student, index) in students" :key="student.id" class="align-middle text-center">
                            <td class="text-center justify-center">{{ index + 1 }}</td>
                            <td class="text-center justify-center">{{ student.session_id }}</td>
                            <td>
                             <b-img :src="student.photo ? student.photo : require('@/assets/img/no-photo.jpg')"  thumbnail style="max-width: 130px" ></b-img>
                            </td>
                            <td class="text-center align-center">{{ student.name }} {{student.surname}}</td>
                                <template v-for="day in weekDaysOptions"  >
                                    <td v-for="(i,key) in hoursInDay" :key="'attendance-' + student.student_program_id + '-' + day.date + '-' + i" >
                                        <b-form-radio-group v-model="selectedAttendance[day.fullYear][i][index].status" :disabled="!day.lessons[key].isDisabled"
                                                            v-if="selectedAttendance[day.fullYear] &&
                                                        selectedAttendance[day.fullYear][i] &&
                                                        selectedAttendance[day.fullYear][i][index]"
                                                            class="d-flex flex-column"
                                        >
                                            <b-form-radio value="P">P</b-form-radio>
                                            <b-form-radio value="L">L</b-form-radio>
                                            <b-form-radio value="A">A</b-form-radio>
                                        </b-form-radio-group>
                                    </td>
                                </template>
                        </tr>
                    </tbody>
                    <b-tfoot>
                        <tr class="border">
                            <td colspan="4"></td>
                            <template v-for="(day) in weekDaysOptions">
                                <td v-for="(i,key) in hoursInDay" :key="'save-button-' + day.date + '-' + i" class="text-center">
                                    <b-button
                                        v-if="day.lessons[key].isDisabled"
                                        variant="success"
                                        size="sm"
                                        @click="saveAttendance(day, i,key)"
                                    >
                                        Save
                                    </b-button>
                                </td>
                            </template>
                        </tr>
                    </b-tfoot>

                </b-table-simple>
            </div>

        </div>
    </app-layout>


</template>

<script>

import EslAttendanceService from "@/services/EslAttendanceService";
import EslInstructorService from "@/services/EslInstructorService";
import moment from 'moment';
import qs from "qs";
import showErrors from "@/helpers/showErrors";
export default {
    data() {
        return {
            instructorId: null,
            week: 1,
            attendances1: null,
            selectedAttendance: {},
            selectedLessons: [],
            students: [],
            attendance: {},
            weeks: [],
            activeWeek: null,
            hoursInDay: null,
            weekDaysOptions: [],
            selectedHours: []
        };
    },
    metaInfo() {
        return {
            title: this.$t('esl_attendance'),
        }
    },
    computed: {
        weekOptions() {
            return this.weeks.map((_, index) => ({
                text: index + 1,
                value: index + 1,
            }));
        },
    },
    watch: {
        week(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.activeWeek = this.weeks[newVal - 1];
                this.getStudents();
            }
        }
    },
    created() {
        this.getStudents(true);
    },
    methods: {
        async getWeekOptions() {
            const dayArrays = this.activeWeek.days.map(item => {
                const date = new Date(item);
                const daysObject = {
                    name: moment(date).locale('en').format('dddd'),
                    date: date.toLocaleDateString("en-US", { month: "short", day: "2-digit" }),
                    fullYear: moment(date).format('MM/DD/YYYY'),
                    disabled: false,
                    unchangedDate: date,
                    lessons: Array.from({ length: this.hoursInDay }, (_, j) => ({
                        name: `lesson ${j + 1}`,
                        isDisabled: false,
                    }))
                };
                return daysObject;
            }).sort((a, b) => a.unchangedDate - b.unchangedDate);

            this.weekDaysOptions = dayArrays;
        },


        async getActiveWeek(weeks) {
            return weeks.find(item => item.is_active);
        },
        async getStudents(isActiveWeek = false) {
            const config = {
                params: {
                    filter: {
                        id: this.$route.params.sessionId,
                    }
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };
            const {id} =localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):null;

            const [attendanceConfigData, studentList] = await Promise.all([
                EslInstructorService.getInstructorSession(id, config),
                EslAttendanceService.getStudents(this.$route.params.sessionId)
            ]);
            this.weeks = attendanceConfigData.data.data[0].weeks;
            this.hoursInDay = attendanceConfigData.data.data[0].hours_in_a_day;
            this.students = studentList.data.data;

            const requestWeek = isActiveWeek ? (this.activeWeek = await this.getActiveWeek(this.weeks), this.week = this.activeWeek.week, this.activeWeek.week) : this.week;

            await this.getWeekOptions();

            const studentAttendance = await EslInstructorService.getWeeksInformation(this.$route.params.sessionId, requestWeek);
            if (studentAttendance.data.success) {
                this.initializeAttendance(studentAttendance.data.data);
            } else {
                this.initializeAttendance();
            }
        },
        initializeAttendance(selectedAttendance = null) {
            if (!this.weekDaysOptions || !this.hoursInDay || !this.students) return;
            this.weekDaysOptions.forEach(day => {
                this.$set(this.selectedAttendance, day.fullYear, {});
                for (let i = 1; i <= this.hoursInDay; i++) {
                    this.$set(this.selectedAttendance[day.fullYear], i, this.students.map(student => ({
                        student_program_id: student.student_program_id,
                        status: 'P'
                    })));
                }
            });
            if (selectedAttendance) {
                Object.entries(selectedAttendance).forEach(([date, hours]) => {
                    if (this.selectedAttendance[date]) {
                        Object.entries(hours).forEach(([hour, entries]) => {
                            if (this.selectedAttendance[date][hour]) {
                                entries.forEach(newEntry => {
                                    const index = this.selectedAttendance[date][hour].findIndex(
                                        entry => entry.student_program_id === newEntry.student_program_id
                                    );
                                    if (index !== -1) {
                                        this.selectedAttendance[date][hour][index] = newEntry;
                                    } else {
                                        this.selectedAttendance[date][hour].push(newEntry);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },

        handleSelectedHours() {
            let count = 0;
            this.weekDaysOptions.forEach(day => {
                day.lessons.forEach(lesson => {
                    if (lesson.isDisabled && count > 0) {
                        lesson.isDisabled = false;
                    } else if (lesson.isDisabled) {
                        count += 1;
                    }
                });
            });
        },

        async saveAttendance(day, hour, lessonKey) {
            if (!this.checkPermission('eslattendance_store')) return this.$toast.error('unauthorized_operation');

            const formData = {
                date: day.fullYear,
                hour: hour,
                session_id: this.$route.params.sessionId,
                esl_attendances: this.selectedAttendance[day.fullYear][hour]
            };

            try {
                const res = await EslInstructorService.saveAttendance(formData);
                this.$toast.success(this.$t("api." + res.data.message));
                day.lessons[lessonKey].isDisabled = false;
                this.getStudents();
            } catch (error) {
                showErrors(error);
            }
        }
    }
};
</script>
